import * as React from 'react';
import { Table, Card, Popconfirm, Input, Modal, Button, Row, Col, Divider, Tag, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import TableForModal from './components/table'
import axios from '../services/axios';
import CustoModal from './components/modal'

interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}
interface TypeData {
  id: number,
  code: string,
  Solde: number
}

interface IPropsSearch {
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any
}

interface Iselect {
  select: () => any
}

const columnModal = [
  {
    title: 'Devise',
    dataIndex: ['devise', 'code'],
    key: 'code',
    render: (text: any, record: any) => <a >{text}</a>,
  },
  {
    title: 'Montant',
    dataIndex: 'solde',
    key: 'solde',
    render: (text: any, record: any) =>
      <Tag style={{ fontSize: 18 }} color={record.solde > 0 ? "red" : (record.solde == 0 ? 'yellow' : 'success')} >
        {parseFloat(record.solde).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + record.devise.code}
      </Tag>,
  },
]

const getRandomuserParams = (params: any) => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const Customers = () => {

  const [data, setData] = React.useState([]);
  const [dataModal, setDataModal] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 100
  });
  const [loading, setLoading] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [client, setClient] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [searchedColumn, setSearchedColumn] = React.useState('');
  const searchInput = React.useRef(null);

  const getCompteByClient = (id: number) => {
    setLoadingModal(true)
    axios.get(
      `/comptes/by-client/${id}`
    ).then(response => {
      setDataModal(response.data);
      response.data.length > 0 ? setClient(response.data[0].client.prenom + ' ' + response.data[0].client.nom + ' / ' + response.data[0].client.telephone) : setClient('');
      setLoadingModal(false)
    }).catch(error => {
      console.log(error);
    })
  }
  const modalShow = (id: number) => {
    setIsModalVisible(true)
    getCompteByClient(id)
  }

  React.useEffect(() => {
    fetchData({ pagination } as IProps);
  }, [])


  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const fetchData = (params: any) => {
    setLoading(true);
    axios.get('/clients')
      .then(response => {
        // const dataPagination = getRandomuserParams(params)
        console.log(response);
        setData(response.data);
        setLoading(false)
        // setPagination({ ...dataPagination.pagination, total: 1000 })
      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: IPropsSearch) => (

      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Vider filtre
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput, 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
      key: 'nom',
      width: '20%',
      ...getColumnSearchProps('nom')
    },
    {
      title: 'Prenom',
      dataIndex: 'prenom',
      sorter: true,
      key: 'prenom',
      width: '20%',
      ...getColumnSearchProps('prenom')
    },
    {
      title: 'Téléphone',
      dataIndex: 'telephone',
      key: 'telephone',
      // filters: [
      //   { text: 'Male', value: 'male' },
      //   { text: 'Female', value: 'female' },
      // ],
      width: '20%',
      ...getColumnSearchProps('telephone')
    },
    {
      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'adresse',
      ...getColumnSearchProps('adresse')
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <>
          <Button> <Link style={{ marginRight: 10 }} to={`/clients/edit/${id}`}>Editer</Link></Button>
          <Button onClick={() => modalShow(id)} >Compte</Button>
        </>
      )
    },

  ];









  return <>
    <Card size="small" title={<h4 className=''> La liste des clients </h4>} extra={<Link to="/add-clients" className="btn btn-primary small">Nouveau</Link>} >
      <Table
        columns={columns}
        rowKey={(record: any) => record.id}
        dataSource={data}
        pagination={{ pageSize: 100 }}
        loading={loading}
      // onChange={handleTableChange}
      />
    </Card>
    <Modal
      title={'Montant par devise de :' + client}
      style={{ top: 20 }}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="Fermer"
    >
      <div>
        <TableForModal columns={columnModal} dataSource={dataModal} loading={loadingModal} />
      </div>
    </Modal>
  </>;
}

export default Customers;