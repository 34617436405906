import * as React from 'react';
import { Table, Card, Form, Row, Col, Select, Tag, InputNumber, Button, } from 'antd';
import { Link } from 'react-router-dom'
import axios from '../services/axios'
import SelectSearchInput from '../utils/components/selectSearchInput';
import DatePickerCustom from '../utils/components/datePicker'
import moment from 'moment'
import { BASE_URL_API } from '../utils/constants';


const { Option } = Select;
interface IProps {
    pagination: {
        pageSize: Number,
        current: Number
    }
}


const Rapport_in_bureau = () => {


    const [form] = Form.useForm();
    const [data, setData] = React.useState([]);
    const [idClient, setIdClient] = React.useState(null);
    const [idDevise, setIdDevise] = React.useState(null);
    const [dateStart, setDateStart] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const [pagination, setPagination] = React.useState({
        current: 1,
        pageSize: 100
    });
    const [loading, setLoading] = React.useState(false);

    const [devises, setDevises] = React.useState([])
    const [clients, setClients] = React.useState([])
    const [compteClient, setCompteClient] = React.useState('')
    const [sumByDevise, setSumByDevise] = React.useState({})
    const [sumOutByDevise, setSumOutByDevise] = React.useState({})
    const [compteByDevise, setCompteByDevise] = React.useState({})
    const [dispoNet, setDispoNet] = React.useState({})


    React.useEffect(() => {
        const dateJour = new Date();
        const dateFormated = moment(dateJour).format('YYYY-MM-DD');

        fetchData({ pagination } as IProps);
        getClient()
        getDevise();
        getSoldeBetweenTwoDate(dateFormated, dateFormated);
    }, [])

    const renderCustomCell = (item: any, name: string) => {
        if (name == 'client') {
            const { client } = item;
            const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
            return clients;
        }
        else if (name == 'deviseentre') {
            const { deviseentre } = item
            return deviseentre.code
        }
        else if (name == 'devisesortie') {
            const { devisesortie } = item
            return devisesortie.code
        }
    };
    let i = 0;
    const columns = [
        {
            title: "N°",
            key: "index",
            render: (row: any, value: any, index: number = 0) => (
                <>
                    {index + 1}
                </>
            ),
        },
        {
            title: 'N° reçu',
            dataIndex: 'numero_recu',
            key: 'numero_recu'
        },
        {
            title: 'Client',
            key: 'client',
            render: (record: any,) => renderCustomCell(record, 'client'),
        },
        {
            title: 'Déposant/Réceveur',
            key: 'nom_deposant',
            render: (nom_deposant: number, row: any) => (
                <>
                    {row.nom_deposant ? (row.nom_deposant + ' ' + row.tel_deposant) : 'Par le client lui méme'}
                </>
            ),
        },
        {
            title: 'Type operation',
            dataIndex: 'type_facture',
            key: 'type_facture',

        },
        {
            title: 'Date',
            dataIndex: 'date_facture',
            key: 'date_facture',
        },
        {
            title: 'Montant',
            dataIndex: 'montant_entre',
            align: 'right',
            key: 'montant_entre',
            render: (montant: any, row: any) => (
                <>
                    <Tag >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + row.deviseentre.code}
                    </Tag>
                </>
            ),

        },
        {
            title: 'Taux',
            dataIndex: 'taux',
            align: 'center',
            key: 'taux',
            render: (montant: any, row: any) => (
                <>
                    <Tag >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </Tag>
                </>
            ),

        },
        {
            title: 'Montant equivalent',
            dataIndex: 'montant_convert',
            align: 'right',
            key: 'montant_convert',
            render: (montant: any, row: any) => (
                <>
                    <Tag color='blue' >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + row.devisesortie.code}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Motif',
            dataIndex: 'motif',
            key: 'motif',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: number) => (
                <>
                    {
                        status == 1 ? (
                            <Tag color='success' >
                                Payé
                            </Tag>
                        ) : (
                            <Tag color='warning' >
                                en attente
                            </Tag>
                        )
                    }
                </>
            ),
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'id',
        //     key: 'id',
        //     render: (id: any, row: any) => (
        //         <>

        //             <Button disabled={row.status !== 1 ? false : true}> <Link style={{ marginRight: 10 }} to={`/factures-entres/edit/${id}`}>Editer</Link></Button>
        //         </>
        //     )
        // },

    ];

    const getRandomuserParams = (params: any) => ({
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
    });


    const onChange = (name: string, value: any) => {
        setLoading(true)
        form.setFieldsValue({ [name]: value })
        console.log('id', value);
        if (name == 'client') {
            setIdClient(value)
            if (idDevise) {
                axios.get(`/factures/rapport/by-devise/client/${dateStart}/${dateEnd}/${value}/${idDevise}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            }
            else {
                axios.get(`/factures/rapport/by-devise/client/${dateStart}/${dateEnd}/${value}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            }
            getSoldeBetweenTwoDate(dateStart, dateEnd);
        }
        else if (name == 'devise') {
            setIdDevise(value)
            if (idClient) {
                axios.get(`/factures/rapport/by-devise/client/${dateStart}/${dateEnd}/${idClient}/${value}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            } else {
                axios.get(`/factures/rapport/by-devise/devise/${dateStart}/${dateEnd}/${value}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            }

        }

    }
    function onSearch(val: string) {
        console.log('search:', val);
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    const getClient = () => {
        axios.get('/clients')
            .then(response => {
                console.log(response);
                setClients(response.data);
                setLoading(false)
            });
    }

    const getSumByDeviseEntresSorties = (start: any, end: any) => {
        axios.get(`/factures/rapport/sommes/by-devise/client/${start}/${end}`)
            .then(response => {
                setSumByDevise(response.data);
                console.log(response.data);
                setLoading(false)
            });
    }

    const fetchData = (params: any) => {
        setLoading(true);
        const dateJour = new Date();
        const dateFormated = moment(dateJour).format('YYYY-MM-DD');
        axios.get(`/factures/type/entree-sortie/${dateFormated}/${dateFormated}`)
            .then(response => {
                const dataPagination = getRandomuserParams(params)
                console.log(response.data);
                setData(response.data)
                setLoading(false)
                setPagination({ ...dataPagination.pagination, total: 20 })
            });
    };
    const getCompteByClient = (id: number) => {
        axios.get(
            `/comptes/by-client/${id}`
        ).then(response => {
            setCompteByDevise(response.data)
            console.log('DATAS', response.data);
            response.data.length > 0 ? setCompteClient(response.data[0].client.prenom + ' ' + response.data[0].client.nom + ' / ' + response.data[0].client.telephone) : setCompteClient('');
        }).catch(error => {
            console.log(error);
        })
    }

    const onDateChange = (dates: any, dateStrings: any) => {
        setLoading(true)
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        setDateStart(dateStrings[0]);
        setDateEnd(dateStrings[1])
        if (idClient != null) {
            if (idDevise != null) {
                axios.get(`/factures/rapport/by-devise/client/${dateStart}/${dateEnd}/${idClient}/${idDevise}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            }
            else {
                axios.get(`/factures/rapport/by-devise/client/${dateStart}/${dateEnd}/${idClient}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        getCompteByClient(idClient as any)
                        setLoading(false)
                    });
            }
        }
        else if (idDevise != null) {
            axios.get(`/factures/rapport/by-devise/devise/${dateStart}/${dateEnd}/${idDevise}`)
                .then(response => {
                    console.log(response.data);
                    setData(response.data)
                    setLoading(false)
                });
        }
        else {
            axios.get(`/factures/type/entree-sortie/${dateStrings[0]}/${dateStrings[1]}`)
                .then(response => {
                    console.log(response.data);
                    setData(response.data)
                    setLoading(false)
                });

        }

        getSoldeBetweenTwoDate(dateStrings[0], dateStrings[1]);

        // getSumByDeviseEntresSorties(dateStrings[0], dateStrings[1])
    }

    const getDevise = () => {
        axios.get('/devises')
            .then(response => {
                console.log(response);
                setDevises(response.data);
                setLoading(false)
            });
    }
    const getSoldeBetweenTwoDate = (start: any, end: any) => {
        if (idClient != null) {
            axios.get(`factures/rapport/sommes-in/by-devise/${start}/${end}/${idClient}`)
                .then(response => {
                    console.log(response.data);
                    setSumByDevise(response.data);
                    axios.get(`factures/rapport/sommes/by-devise/client/${start}/${end}/${idClient}`)
                        .then(res => {
                            console.log('res', res.data);
                            setSumOutByDevise(res.data);
                            let diff = sumObjectsByKey(response.data, res.data);
                            console.log("diff", diff);
                            setDispoNet(diff)
                        });

                });
        }
        else {
            axios.get(`factures/rapport/sommes-in/by-devise/${start}/${end}`)
                .then(response => {
                    console.log(response.data);
                    setSumByDevise(response.data);
                    axios.get(`factures/rapport/sommes-out/by-devise/${start}/${end}`)
                        .then(res => {
                            console.log('res', res.data);
                            setSumOutByDevise(res.data);
                            let diff = sumObjectsByKey(response.data, res.data);
                            console.log("diff", diff);
                            setDispoNet(diff)
                        });

                });
        }

    }

    function sumObjectsByKey(ob1: any, ob2: any) {
        let sum: any = {};
        Object.keys(ob1).forEach(key => {
            if (ob2.hasOwnProperty(key)) {
                sum[key] = ob1[key] - ob2[key]
            }
        })
        return sum;
    }

    return <>
        <Card size="small" title={<h4>Liste des operations entrées/sorties</h4>}
            extra={
                idClient ?
                    (
                        idDevise ?
                            (<a target="_bland" href={`${BASE_URL_API}/factures/rapports/transations-by-client-devise/${dateStart}/${dateEnd}/${idClient}/${idDevise}`}>
                                <Button type="primary">Imprimer les transations par client</Button>
                            </a>)
                            : (<a target="_bland" href={`${BASE_URL_API}/factures/rapports/transations-by-client/${dateStart}/${dateEnd}/${idClient}`}>
                                <Button type="primary">Imprimer les transations par client</Button>
                            </a>)
                    ) : ""
            }>
            <Row justify='end'>
                <Form form={form} layout='inline'>
                    <Form.Item label='Devise'>
                        <SelectSearchInput
                            onChange={(v) => onChange('devise', v)}
                            onSearch={(value) => onSearch(value)}
                            data={devises}
                            label='code'
                            valueLabel='id'
                            placeholder="Selectionnez la devise"
                        />
                    </Form.Item>

                    <Form.Item label='Client' >
                        <SelectSearchInput
                            onChange={(v) => onChange('client', v)}
                            onSearch={(value) => onSearch(value)}
                            data={clients}
                            label='prenom'
                            label2='nom'
                            label3='telephone'
                            valueLabel='id'
                            placeholder="Selectionnez le client"
                        />
                    </Form.Item>

                    <Form.Item label="Dates" >
                        <DatePickerCustom
                            onChange={onDateChange}
                        />
                    </Form.Item>
                </Form>
            </Row>
            <br />
            <br />
            <Table
                columns={columns as any}
                rowKey={(record: any) => record.login}
                dataSource={data}
                pagination={{ pageSize: 100 }}
                loading={loading}
            // onChange={handleTableChange}
            />
            <>

                <br />
                <Row >
                    <Col span={4}>
                        <p>Sommes des Entrées:</p>
                    </Col>
                    {
                        Object.entries(sumByDevise).map(
                            (val, index) => <Col span={4}>
                                <Form.Item label={`Total en ${val[0]}`} >
                                    <InputNumber
                                        disabled
                                        value={val[1] as number}
                                        style={{ width: '100%', fontSize: 15 }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>

                <Row >
                    <Col span={4}>
                        <p>Sommes des Sorties:</p>
                    </Col>
                    {
                        Object.entries(sumOutByDevise).map(
                            (val, index) => <Col span={4}>
                                <Form.Item label={`Total en ${val[0]}`} >
                                    <InputNumber
                                        disabled
                                        value={val[1] as number}
                                        style={{ width: '100%', fontSize: 15 }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>


                <Row >
                    <Col span={4}>
                        <p>Disponible net: </p>
                    </Col>
                    {
                        Object.entries(dispoNet).map(
                            (val, index) => <Col span={4}>
                                <Form.Item label={`Total en ${val[0]}`} >
                                    <InputNumber
                                        disabled
                                        value={val[1] as number}
                                        style={{ width: '100%', fontSize: 15 }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>

            </>
        </Card>
    </>;
}

export default Rapport_in_bureau;