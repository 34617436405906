import React from 'react';
import axios from "../../services/axios"
import { Card, Row, Col, Form, Input, Tag, Table, Select, DatePicker, Divider, Space, InputNumber } from 'antd'
import moment from 'moment'
const CompteEntreprise: React.FC = () => {

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    getCompteGlobale()
  }, [])

  const getCompteGlobale = () => {
    axios.get('/comptes_globales/soldes')
      .then(response => {
        console.log(response);
        setLoading(false)
        setData([]);
        Array.prototype.push.apply(response.data[0], response.data[1]);
        setData(response.data[0]);
        const data: any = sumArray(response.data[0])
        setData(data);
      });
  }

  const sumArray = (data: any[]) => {
    let sumSoldeByDevise = 0
    let result = []
    let devise = []
    for (let i = 0; i < data.length; i++) {
      let a = data[i]
      if (devise.indexOf(a.devise.code) == -1) {
        devise.push(a.devise.code)
        data.forEach(item => {
          if (item.devise.code == a.devise.code) {
            sumSoldeByDevise += item.solde
          }
        })
        var n = a.devise.code;
        result.push({
          ["solde"]: sumSoldeByDevise,
          ['id']: a.id,
          ['devise_id']: a.devise_id,
          ['description']: a.description,
          ['devise']: a.devise,
          ['created_at']: a.created_at,
          ['updated_at']: a.updated_at,
        });
      }
      sumSoldeByDevise = 0
    }
    return result;
  }
  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'Devise',
      dataIndex: ['devise', 'code'],
      key: 'code',
      render: (text: any, record: any) => <a >{text}</a>,
    },
    {
      title: 'Montant',
      dataIndex: 'solde',
      key: 'solde',
      render: (montant: any, record: any) => (
        <>
          <Tag style={{ fontSize: 18 }} >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + record.devise.code}
          </Tag>
          { }
        </>
      ),
    },
  ]
  const dj = new Date()
  return <>
    <Card
      style={{ marginTop: 16 }}
      type="inner"
      title={`LES SOLDES DE L'ENTREPRISE le ${moment(dj).format('YYYY-MM-DD hh:mm:s')}`}

    >
      <Row>
        <Col span={24}  >
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
          />
        </Col>
      </Row>
    </Card>
  </>;
}

export default CompteEntreprise;