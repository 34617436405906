import React from 'react';
import { Link } from 'react-router-dom'
import { Table, Tag, Space, Card, Popconfirm, Spin, Row, Col, Form, DatePicker, Select, InputNumber, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FormModal from '../components/modalForm'

const { RangePicker } = DatePicker;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const data = [
  {
    title: "N°",
    key: "index",
    render: (row: any, value: any, index: number = 0) => (
      <>
        {index + 1}
      </>
    ),
  },
  {
    key: '1',
    devise_entre: 'Euro',
    montant: 300000000,
    client: 'developer',
    date: '14-02-2021'
  },
  {
    key: '2',
    devise_entre: 'USD',
    montant: 3000,
    client: 'developer',
    date: '14-02-2021'
  },
  {
    key: '3',
    devise_entre: 'GNF',
    montant: 3000,
    client: 'developer',
    date: '14-02-2021'
  },
];

const CompteClient = () => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const onCreate = (values: any) => {
    console.log('Received values of form: ', values);
    setVisible(false);
  };

  function onChange(value: string) {
    console.log(`selected ${value}`);
  }
  function onSearch(val: string) {
    console.log('search:', val);
  }
  const handleOk = () => {
    console.log('Clicked on button');
    setConfirmLoading(true);
    setTimeout(() => {
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
  };

  const columns = [
    {
      title: 'N°',
      dataIndex: 'key',
      key: 'key',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: 'Devise Entre',
      dataIndex: 'devise_entre',
      key: 'devise_entre',
    },
    {
      title: 'Montant',
      dataIndex: 'montant',
      render: (montant: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Tag>
        </>
      ),
      key: 'montant',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: { key: React.Key }) => (
        <Space size="middle">
          <Popconfirm
            title="Confirmez vous ce paiement?"
            onConfirm={handleOk}
            okText={(<><Spin spinning={confirmLoading} indicator={antIcon} />   OUI </>)}
            cancelText='Non'
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <a type="primary" > Detail </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return <>
    <Card size="small" title="Les des comptes clients" extra={"detail"}>
      <Row justify='end'>
        <Form layout='inline'>
          <Form.Item label="Dates">
            <RangePicker />
          </Form.Item>
          <Form.Item label='Devise'>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="selectionner la devise"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="gnf">GNF</Option>
              <Option value="usd">USD</Option>
              <Option value="eur">EUR</Option>
            </Select>

          </Form.Item>
          <Form.Item label='Client'>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="selectionner le client"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="gnf">Mamadou</Option>
              <Option value="usd">Idrissa</Option>
              <Option value="eur">Saliou</Option>
            </Select>

          </Form.Item>
        </Form>
      </Row>
      <br />
      <br />
      <Table columns={columns} dataSource={data} />
      <Row justify='end'>
        <Col span={6}>
          <Form.Item label='Montant total sortie' >
            <InputNumber
              disabled
              style={{ width: '100%' }}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <FormModal
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      /> */}
    </Card>
  </>;
}

export default CompteClient;