import React, { useState } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Card,
  Button,
  Tabs,
  AutoComplete,
  message
} from 'antd';
import { Redirect } from "react-router-dom"
import { QuestionCircleOutlined } from '@ant-design/icons';
import axios from '../../services/axios'
import SelectSearchInput from '../../utils/components/selectSearchInput';

const { Option } = Select;
const { TabPane } = Tabs;
const residences = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
          },
        ],
      },
    ],
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Forms = () => {
  const [form] = Form.useForm();
  const [roles, setRoles] = React.useState([])
  const [redirect, setRedirect] = React.useState(false)
  const [loadint, setLoading] = React.useState(false)

  React.useEffect(() => {
    getRoles()
  }, [])
  const onFinish = (values: any) => {
    console.log('values', values);
    setLoading(true);
    axios.post('/registers/new', values)
      .then(function (response) {
        console.log(response);
        setRedirect(true)
        setLoading(false);
        message.success("L'utilisateur ajouté avec succé");
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        message.success(error.error);
        setLoading(false);
      });

    form.resetFields();
  };

  const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map(domain => `${value}${domain}`));
    }
  };

  const getRoles = () => {
    axios.get('/roles')
      .then(response => {
        console.log('Roles ', response.data);
        setRoles(response.data);
        // setLoading(false)
      });
  }

  const onSearch = (val: any) => {
    console.log(val);
  }

  const onChange = (name: string, value: any) => {
    form.setFieldsValue({ [name]: value })
  }

  const websiteOptions = autoCompleteResult.map(website => ({
    label: website,
    value: website,
  }));

  return (
    <Tabs defaultActiveKey="1" >
      <TabPane tab="Information de connexion" key="1">
        <Row>
          <Col span={16} offset={4}>
            <Card size="small" title="Ajouter un nouveau utilisateur" >
              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}

                scrollToFirstError
              >

                <Form.Item
                  name="prenom"
                  label="Prenom"
                  rules={[
                    {
                      required: true,
                      message: 'Ce champ est obligatoire!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="nom"
                  label="Nom"
                  rules={[
                    {
                      required: true,
                      message: 'Ce champ est obligatoire!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="username"
                  label="Idetnetifant(login)"
                  rules={[
                    {
                      required: true,
                      message: 'Ce champ est obligatoire!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Mot de passe"
                  rules={[
                    {
                      required: true,
                      message: 'ce champ est obligatoire!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirme mot de passe"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Ce champ est obligatoire!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Les deux mots de passe que vous avez entrés ne correspondent pas!');
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="telephone"
                  label="Numéro de telephone"
                >
                  <Input placeholder="telephone" />
                </Form.Item>
                <Form.Item name='roles' label="Roles" rules={[{ required: true }]}>

                  <SelectSearchInput
                    onChange={(v) => onChange('role', v)}
                    data={roles}
                    label='display_name'
                    valueLabel='id'
                    onSearch={(value) => onSearch(value)}
                    placeholder="sectionnez le role "
                  />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">
                    Enregistrer
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </TabPane>
      {
        redirect ? (<Redirect to="/utilisateurs" />) : ''
      }
    </Tabs>

  );
};
export default Forms