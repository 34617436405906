import React from 'react';
import { Link } from 'react-router-dom'
import { Table, Tag, Space, Card } from 'antd';
import axios from '../services/axios'

interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}


const Devises = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10
  });

  React.useEffect(() => {
    fetchData({ pagination } as IProps);
  }, [])

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      key: 'libelle',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (text: string, record: any) => (
    //     <Space size="middle">
    //       <Link to={`/add-devise/edit/${record.id}`}>Editer</Link>
    //       <a>Supprimer</a>
    //     </Space>
    //   ),
    // },
  ];


  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const fetchData = (params: any) => {
    setLoading(true);
    axios.get('/devises')
      .then(response => {
        const dataPagination = getRandomuserParams(params)
        console.log(response);
        setData(response.data);
        setLoading(false)
        setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };


  return <>
    <Card size="small" title={<h4 className="text-center">La liste des devises</h4>} >
      <Table
        columns={columns}
        rowKey={(record: any) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </Card>
  </>;
}

export default Devises;