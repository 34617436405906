import React from 'react';
import { Link, Redirect, useParams } from 'react-router-dom'
import { Table, Tag, Space, Card, Popconfirm, Spin, Row, Col, Form, DatePicker, Select, InputNumber, Button, Pagination } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from '../services/axios'
import SelectSearchInput from '../utils/components/selectSearchInput';
import DatePickerCustom from '../utils/components/datePicker';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
    pagination: {
        pageSize: Number,
        current: Number
    }
}

const Hors_bureau = () => {
    const { id }: any = useParams()
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [redirect, setRedirect] = React.useState(false)
    const [pagination, setPagination] = React.useState({
        current: 1,
        pageSize: 100
    });
    const [loading, setLoading] = React.useState(false);
    const [devises, setDevises] = React.useState([])
    const [clients, setClients] = React.useState([])
    const [sumDepotByDevise, setSumDepotByDevise] = React.useState({})
    const [sumRetraitByDevise, setSumRetraitByDevise] = React.useState({})
    const [dispoNet, setDispoNet] = React.useState({})
    const [defaultValue, setDefaultValue] = React.useState({})
    const [idClient, setIdClient] = React.useState(null);
    const [idDevise, setIdDevise] = React.useState(null);
    const [dateStart, setDateStart] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const [form] = Form.useForm();

    React.useEffect(() => {
        const dateJour = new Date();
        const dateFormated = moment(dateJour).format('YYYY-MM-DD');
        fetchData({ pagination } as IProps);
        getClient()
        getDevise()
        getSoldeBetweenTwoDate(dateFormated, dateFormated);
    }, [])

    const getRandomuserParams = (params: any) => ({
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
    });
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    const getClient = () => {
        axios.get('/clients')
            .then(response => {
                console.log(response);
                setClients(response.data);
                setLoading(false)
            });
    }

    const fetchData = (params: any) => {
        setLoading(true);
        const dateJour = new Date();
        const dateFormated = moment(dateJour).format('YYYY-MM-DD');
        axios.get(`/operations/type/depot-retrait/${dateFormated}/${dateFormated}`)
            .then(response => {
                const dataPagination = getRandomuserParams(params)
                console.log(response.data);
                setData(response.data)
                setLoading(false)
                setPagination({ ...dataPagination.pagination, total: 20 })
            });
    };



    const onChange = (name: string, value: any) => {
        setLoading(true);
        form.setFieldsValue({ [name]: value })
        if (name == 'client') {
            setIdClient(value)
            if (idDevise != null) {
                axios.get(`/operations/type/depot-retrait/client-devise/${dateStart}/${dateEnd}/${value}/${idDevise}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            }
            else {
                axios.get(`/operations/type/depot-retrait/client/${dateStart}/${dateEnd}/${value}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            }
            getSoldeBetweenTwoDate(dateStart, dateEnd);
        }
        else if (name == 'devise') {
            setIdDevise(value)
            if (idClient != null) {
                axios.get(`/operations/type/depot-retrait/client-devise/${dateStart}/${dateEnd}/${idClient}/${value}`)
                    .then(response => {
                        console.log("CLIENT CHANGED", response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            } else {
                axios.get(`/operations/type/depot-retrait/devise/${dateStart}/${dateEnd}/${value}`)
                    .then(response => {
                        console.log(response.data);
                        setData(response.data)
                        setLoading(false)
                    });
            }

        }

    }
    function onSearch(val: string) {
        console.log('search:', val);
    }
    const handleOk = () => {
        console.log('Clicked on button');
        setConfirmLoading(true);
        setTimeout(() => {
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
    };

    const getDevise = () => {
        axios.get('/devises')
            .then(response => {
                console.log(response);
                setDevises(response.data);
                setLoading(false)
            });
    }

    const renderCustomCell = (item: any, name: string) => {
        if (name == 'client') {
            const { client } = item;
            const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
            return clients;
        }
        else if (name == 'devise') {
            const { devise } = item
            return devise.code
        }
    };

    let i = 0;
    const columns = [
        {
            title: "N°",
            key: "index",
            render: (row: any, value: any, index: number = 0) => (
                <>
                    {index + 1}
                </>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date_operation',
            key: 'date_operation',
        },
        {
            title: 'N° Réçu',
            dataIndex: 'numero_recu',
            key: 'numero_recu',
        },
        {
            title: 'Déposant/Réceveur',
            key: 'nom_deposant',
            render: (nom_deposant: number, row: any) => (
                <>
                    {row.nom_deposant ? (row.nom_deposant + ' ' + row.tel_deposant) : 'Par le client lui méme'}
                </>
            ),
        },
        {
            title: 'Type operation',
            dataIndex: 'type_operation',
            // filters: [
            //     { text: 'Depôt', value: 'depot' },
            //     { text: 'Retrait', value: 'retrait' },
            // ],
            key: 'type_operation',

        },
        {
            title: 'Client',
            key: 'client',
            sorter: (a: any, b: any) => a.client.prenom.length - b.client.prenom.length,
            sortDirections: ['descend', 'ascend'],
            render: (record: any,) => renderCustomCell(record, 'client'),
        },
        {
            title: 'Montant',
            dataIndex: 'montant',
            align: "right",
            key: 'montant',
            render: (montant: any, record: any) => (
                <>
                    <Tag >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + record.devise.code}
                    </Tag>
                    { }
                </>
            ),

        },
        {
            title: 'Taux',
            dataIndex: 'taux',
            align: 'center',
            key: 'taux',
            render: (montant: any, row: any) => (
                <>
                    <Tag >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Montant equivalent',
            dataIndex: 'montant_sortie',
            align: 'right',
            key: 'montant_sortie',
            render: (montant: any, row: any) => (
                <>
                    <Tag color='blue' >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + row.devisesortie.code}
                    </Tag>
                </>
            ),
        },

        {
            title: 'Motif',
            dataIndex: 'motif',
            key: 'motif',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: number) => (
                <>
                    {
                        status == 1 ? (
                            <Tag color='success' >
                                Payé
                            </Tag>
                        ) : (
                            <Tag color='warning' >
                                en attente
                            </Tag>
                        )
                    }
                </>
            ),
        },

    ];

    const onDateChange = (dates: any, dateStrings: any) => {
        setLoading(true)
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        setDateStart(dateStrings[0]);
        setDateEnd(dateStrings[1])

        if (idClient != null) {
            axios.get(`/operations/type/depot-retrait/client/${dateStart}/${dateEnd}/${idClient}`)
                .then(response => {
                    console.log(response.data);
                    setData(response.data)
                    setLoading(false)
                });
        }
        else {
            axios.get(`/operations/type/depot-retrait/${dateStrings[0]}/${dateStrings[1]}`)
                .then(response => {
                    console.log(response.data);
                    setData(response.data)
                    setLoading(false)
                });
        }
        getSoldeBetweenTwoDate(dateStrings[0], dateStrings[1])
    }

    const getSoldeBetweenTwoDate = (start: any, end: any) => {
        if (idClient != null) {
            axios.get(`/operations/type/depot/client/${start}/${end}/${idClient}`)
                .then(response => {
                    console.log(response.data);
                    setSumDepotByDevise(response.data);
                    axios.get(`/operations/type/retrait/client/${start}/${end}/${idClient}`)
                        .then(res => {
                            console.log('res', res.data);
                            setSumRetraitByDevise(res.data);
                            let diff = sumObjectsByKey(response.data, res.data);
                            console.log("diff", diff);
                            setDispoNet(diff)
                        });
                });
        }
        else {
            axios.get(`/operations/rapport/sum-depot/by-devise/${start}/${end}`)
                .then(response => {
                    console.log(response.data);
                    setSumDepotByDevise(response.data);
                    axios.get(`/operations/rapport/sum-retrait/by-devise/${start}/${end}`)
                        .then(res => {
                            console.log('res', res.data);
                            setSumRetraitByDevise(res.data);
                            let diff = sumObjectsByKey(response.data, res.data);
                            console.log("diff", diff);
                            setDispoNet(diff)
                        });

                });
        }

    }
    function sumObjectsByKey(ob1: any, ob2: any) {
        let sum: any = {};
        Object.keys(ob1).forEach(key => {
            if (ob2.hasOwnProperty(key)) {
                sum[key] = ob1[key] - ob2[key]
            }
        })
        return sum;
    }

    return <>
        <Card size="small" title={<h4>Liste des operations depôt/retrait</h4>} extra={""} >
            <Row justify='end'>
                <Form form={form} layout='inline'>
                    <Form.Item label='Devise'>
                        <SelectSearchInput
                            onChange={(v) => onChange('devise', v)}
                            onSearch={(value) => onSearch(value)}
                            data={devises}
                            label='code'
                            valueLabel='id'
                            placeholder="Selectionnez la devise d'entre"
                        />
                    </Form.Item>

                    <Form.Item label='Client' >
                        <SelectSearchInput
                            onChange={(v) => onChange('client', v)}
                            onSearch={(value) => onSearch(value)}
                            data={clients}
                            label='prenom'
                            label2='nom'
                            label3='telephone'
                            valueLabel='id'
                            placeholder="Selectionnez le client"
                        />
                    </Form.Item>

                    <Form.Item label="Dates" >
                        <DatePickerCustom
                            onChange={onDateChange}
                        />
                    </Form.Item>
                </Form>
            </Row>
            <br />
            <br />
            <Table
                columns={columns as any}
                rowKey={(record: any) => record.login}
                dataSource={data}
                pagination={{ pageSize: 100 }}
                loading={loading}
            // onChange={handleTableChange}
            />
            <br />
            <Row >
                <Col span={4}>
                    <p>Sommes des Depots:</p>
                </Col>
                {
                    Object.entries(sumDepotByDevise).map(
                        (val, index) => <Col span={4}>
                            <Form.Item label={`Total en ${val[0]}`} >
                                <InputNumber
                                    disabled
                                    value={val[1] as number}
                                    style={{ width: '100%', fontSize: 15 }}
                                    formatter={value => `${parseInt(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>

            <Row >
                <Col span={4}>
                    <p>Sommes des Retraits:</p>
                </Col>
                {
                    Object.entries(sumRetraitByDevise).map(
                        (val, index) => <Col span={4}>
                            <Form.Item label={`Total en ${val[0]}`} >
                                <InputNumber
                                    disabled
                                    value={val[1] as number}
                                    style={{ width: '100%', fontSize: 15 }}
                                    formatter={value => `${parseInt(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>


            <Row >
                <Col span={4}>
                    <p>Disponible net: </p>
                </Col>
                {
                    Object.entries(dispoNet).map(
                        (val, index) => <Col span={4}>
                            <Form.Item label={`Total en ${val[0]}`} >
                                <InputNumber
                                    disabled
                                    value={val[1] as number}
                                    style={{ width: '100%', fontSize: 15 }}
                                    formatter={value => `${parseInt(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>

        </Card>
    </>;
}

export default Hors_bureau;