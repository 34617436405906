import React from 'react';
import { Card, Row, Col } from 'antd';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import axios from '../services/axios';

export default function Dashboard() {
    const [labelClient, setLabelClient] = React.useState([]);
    const [nbreClient, setNbreClient] = React.useState([]);

    const [labelTrans, setLabelTrans] = React.useState([]);
    const [nbreTrans, setNbreTrans] = React.useState([]);

    const [nbreDetteCredit, setNbreDetteCredit] = React.useState([]);

    React.useEffect(() => {
        getCountClient();
        getCountTransactionDevise();
        getCountCreditDettes();
    }, [])

    const getCountClient = () => {
        axios.get('/rapports/top-performance')
            .then((response: any) => {
                console.log(response);
                console.log('count', response.data[0]);
                console.log('count', response.data[1]);
                setLabelClient(response.data[0]);
                setNbreClient(response.data[1]);
                //    setLoading(false)
            });
    }

    const getCountTransactionDevise = () => {
        axios.get('/rapports/transactions-by-devise')
            .then((response: any) => {
                console.log(response);
                setLabelTrans(response.data[0]);
                setNbreTrans(response.data[1]);
                //    setLoading(false)
            });
    }

    const getCountCreditDettes = () => {
        axios.get('/rapports/credit-Dettes')
            .then((response: any) => {
                console.log(response);
                setNbreDetteCredit(response.data);
                //    setLoading(false)
            });
    }

    const data = {
        labels: labelClient,
        datasets: [
            {
                label: 'Performance clients',
                data: nbreClient,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const nbreTransDevise = {
        labels: labelTrans,
        datasets: [
            {
                label: 'Nombre de transaction par devise',
                data: nbreTrans,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };



    const nbreCredit = {
        labels: ['Dettes', 'Crédits'],
        datasets: [
            {
                label: 'Les Dettes et crédits',
                data: nbreDetteCredit,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12} >
                    <Card type="inner" title="Top 10 des clients les plus performants" extra={<a href="#"></a>}>
                        <Bar data={data} options={options} />
                    </Card>
                </Col>
                <Col span={12} >
                    <Card type="inner" title="Transactions par devise" extra={<a href="#"></a>}>
                        <Bar data={nbreTransDevise} options={options} />
                    </Card>
                </Col>
                <Col span={12} >
                    <Card type="inner" title="Crédits par devise" extra={<a href="#"></a>}>
                        <Pie data={nbreCredit} />
                    </Card>
                </Col>
                <Col span={12} >
                    <Card type="inner" title="Dettes par devise" extra={<a href="#"></a>}>
                        <Doughnut data={data} />
                    </Card>
                </Col>
            </Row>

        </>
    );
}
