import React from 'react';
import { Link } from 'react-router-dom'
import { Table, Tag, Space, Card, Button } from 'antd';
import {
  EditTwoTone,
  DeleteTwoTone
} from '@ant-design/icons';
import axios from '../services/axios'

const columns = [
  {
    title: 'N°',
    dataIndex: 'key',
    key: 'key',
    render: (text: string) => <a>{text}</a>,
  },
  {
    title: 'Prenom',
    dataIndex: 'prenom',
    key: 'prenom',
  },
  {
    title: 'Nom',
    dataIndex: 'nom',
    key: 'nom',
  },
  {
    title: 'Téléphone',
    dataIndex: 'telephone',
    key: 'telephone',
  },
  {
    title: 'Nom d\'utilisateur',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Role',
    key: 'roles',
    render: (record: any) => <Tag>{record.roles[0].name}</Tag>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: string, record: any) => (
      <Space size="middle">
        <a><EditTwoTone /> Editer {record.name}</a>
        <Button danger><DeleteTwoTone /> Bloquer</Button>
        <Button type="primary">Réinitialiser mot de passe</Button>
      </Space>
    ),
  },
];

const renderCustomCell = (item: any, name: string) => {
  if (name == 'roles') {
    const { roles } = item;
    if (roles.lenght) {
      return roles[0].name
    }
    return null

  }
};

const Users = () => {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    fetchUsers()
  }, [])
  const fetchUsers = () => {
    setLoading(true)
    axios.get('/users')
      .then(response => {
        console.log('users ', response.data);
        setData(response.data);
        setLoading(false)
      });
  }
  return <>
    <Card size="small" title="La liste des utilisateurs" extra={<Link to="add-users">Nouveau</Link>} >
      <Table columns={columns} dataSource={data} loading={loading} />
    </Card>
  </>;
}

export default Users;