import React from 'react';
import { Card, Row, Col, Form, Input, InputNumber, Button, Space } from 'antd';
import { Redirect, useParams } from 'react-router-dom'
import axios from '../../services/axios'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


const Forms = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false)
  const [editloading, setEditLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState(false)
  const [devise, setDevise] = React.useState({})
  const [libelle, setLibelle] = React.useState('')
  const [code, setCode] = React.useState('')
  const [description, setDescription] = React.useState('')
  let { id }: any = useParams()
  React.useEffect(() => {
    if (id) {
      getSingleCurrency(id)
    }

  }, [])

  const getSingleCurrency = (id: number) => {
    axios.get(`/devises/show/${id}`)
      .then(response => {
        console.log('result', response.data);
        form.setFieldsValue(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.log('error not found', error);
      });
  }
  const updateDevise = (data: any[], id: number) => {
    console.log(data);
    setEditLoading(true);
    axios.put(`/devises/edit/${id}`, data)
      .then(function (response) {
        console.log(response);
        setRedirect(true)
        setEditLoading(false);
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        setEditLoading(false);
      });
  }
  const onFinish = (values: any) => {
    console.log(values);
    setLoading(true);

    if (id) {
      updateDevise(values, id);
    }

    axios.post('/devises/new', values)
      .then(function (response) {
        console.log(response);
        setRedirect(true)
        setLoading(false);
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

    form.resetFields();
  };
  const onReset = () => {
    form.resetFields();
  }

  return <>
    <Row>
      <Col span={12} offset={6}>
        <Card size="small" title="Ajouter une nouvelle devise"  >
          <Form {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            initialValues={devise}
          >
            <Form.Item name='code' label="Code" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name='libelle' label="Libelle" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item initialValue={description ? description : ''} name='description' label="Description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Space>
                {
                  !id ? (
                    <Button loading={loading} type="primary" htmlType="submit">
                      Enregistrer
                    </Button>
                  ) : (
                    <Button loading={editloading} type="primary" htmlType="submit">
                      Modifier
                    </Button>
                  )
                }
                <Button htmlType="button" onClick={onReset}>
                  Annuler
            </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
    {
      redirect ? (<Redirect to="/devises/" />) : ''
    }
  </>;
}

export default Forms;