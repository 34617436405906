import React from 'react';
import { Card, Row, Col, Form, Input, InputNumber, Button, Select, DatePicker, Divider, Space } from 'antd'
import SelectSearchInput from '../../../utils/components/selectSearchInput';
import { PoweroffOutlined } from '@ant-design/icons';
import { Redirect, useParams } from 'react-router-dom'
import FormModal from '../../../customers/components/FormModal'
import axios from '../../../services/axios'
import moment from 'moment'

const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const config = {
  rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
};
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

const Add = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState(false)
  const [showButton, setShowButton] = React.useState(true)
  const [visible, setVisible] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [devises, setDevises] = React.useState([]);
  const [editloading, setEditLoading] = React.useState(false)
  const [montantSortieNet, setMontantSortieNet] = React.useState();
  const [taux, setTaux] = React.useState({});

  let { id }: any = useParams()

  React.useEffect(() => {
    getDevise()
    getClient()
    if (id) {
      getSingleFactures(id)
    }
  }, [])

  const getClient = () => {
    axios.get('/clients')
      .then(response => {
        console.log(response);
        setClients(response.data);
        setLoading(false)
      });
  }
  const getDevise = () => {
    axios.get('/devises')
      .then(response => {
        console.log(response);
        setDevises(response.data);
        setLoading(false)
      });
  }

  const onFinish = (values: any) => {
    console.log(values);
    setLoading(true);
    // const date_facture = values.date_facture.format('YYYY-MM-DD')
    if (id) {
      updateFacturesEntre(values, id);
    }
    else {
      console.log(values.date_facture);
      axios.post('/factures/new', values)
        .then(function (response) {
          console.log(response);
          setRedirect(true)
          setLoading(false);
          form.resetFields();
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    }
  };
  const onReset = () => {
    form.resetFields();
  }
  const onChange = (name: string, value: any) => {
    const t: any = taux;
    console.log(value);
    if (name == "devise_entre" && value == "USD") {
      form.setFieldsValue({ 'taux_jour': t.taux_USD })
      form.setFieldsValue({ [name]: value })
    }
    else if (name == "devise_entre" && value == "Euro") {
      form.setFieldsValue({ 'taux_jour': t.taux_EURO })
      form.setFieldsValue({ [name]: value })
    }
    else if (name == "devise_entre" && value == "FCFA") {
      form.setFieldsValue({ 'taux_jour': t.taux_FCFA })
      form.setFieldsValue({ [name]: value })
    }
    form.setFieldsValue({ [name]: value })
  }

  const onSearch = (val: any) => {
    console.log(val);
  }

  const getTauxJour = () => {
    axios.get(`/taux-jours/lastest/row`)
      .then(response => {
        console.log('result', response.data);
        setTaux(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.log('error not found', error);
      });
  }

  const getAmountConvert = () => {
    const data = {
      "devise_entre": form.getFieldValue('devise_entre'),
      "devise_sortie": form.getFieldValue('devise_sortie'),
      "montant_entre": form.getFieldValue('montant_entre'),
      "taux": form.getFieldValue('taux'),
    }
    axios.post(`devises/convert`, data)
      .then((response) => {
        form.setFieldsValue({ 'montant_convert': response.data })
        form.setFieldsValue({ 'montant_sortie': response.data })
      }).catch((err) => {
        console.log(err);
      });
  }

  const onMontantChange = (value: number) => {
    // const isValue = typeof value === 'number'
    if (form.getFieldValue('devise_entre') && form.getFieldValue('devise_sortie') && form.getFieldValue('taux')) {
      // setShowButton(true);
      getAmountConvert()
    }
    else setShowButton(false)
  }
  const onMontantFraisChange = (value: number) => {
    // const isValue = typeof value === 'number';
    const amountOut: number = form.getFieldValue('montant_convert') - value;
    setMontantSortieNet(amountOut as any)
    console.log('net', montantSortieNet);
    if (form.getFieldValue('devise_entre') && form.getFieldValue('devise_sortie') && form.getFieldValue('taux') && form.getFieldValue('montant_convert')) {
      form.setFieldsValue({ montant_sortie: amountOut })
      form.setFieldsValue({ 'montant_reste': 0 })
    }

  }

  const convertAmount = () => {
    getAmountConvert()
    form.setFieldsValue({ montant_sortie: 1000 })
    form.setFieldsValue({ montant_sortie: 1000 })
    console.log('recupere', form.getFieldValue('devise_entre'))
  }

  const onAllChange = (values: any) => {
    console.log('values change', values)
  }

  const onMontantSortieChange = (value: any) => {
    if (form.getFieldValue('montant_convert') && form.getFieldValue('montant_benefice') && montantSortieNet) {
      const amountOut: any = montantSortieNet;
      const reste = amountOut - value;
      form.setFieldsValue({ 'montant_reste': reste })
    }
  }

  const onDateChange = (value: any) => {
    var yourDate = value.format('YYYY-MM-DD');
    form.setFieldsValue({ 'date_facture': value })
  }

  const onCreate = (values: any) => {
    console.log('Received values of form:  bobobo', values);
    axios.post('/clients/new', values)
      .then(function (response) {
        getClient()
        console.log(response);
        setLoading(false);
        form.resetFields();
        setVisible(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const updateFacturesEntre = (data: any[], id: number) => {
    console.log(data);
    setEditLoading(true);
    axios.put(`/factures/edit/${id}`, data)
      .then(function (response) {
        console.log(response);
        setRedirect(true)
        setEditLoading(false);
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        setEditLoading(false);
      });
  }

  const getSingleFactures = (id: number) => {
    axios.get(`/factures/${id}`)
      .then(response => {
        console.log('result', response.data);
        // form.setFieldsValue(response.data)
        form.setFieldsValue({ 'date_facture': moment(response.data.date_facture) })
        form.setFieldsValue({ 'numero_recu': response.data.numero_recu })
        form.setFieldsValue({ 'nom_deposant': response.data.nom_deposant })
        form.setFieldsValue({ 'tel_deposant': response.data.tel_deposant })
        form.setFieldsValue({ 'motif': response.data.motif })
        form.setFieldsValue({ 'taux': response.data.taux })
        form.setFieldsValue({ 'montant_entre': response.data.montant_entre as number })
        form.setFieldsValue({ 'montant_sortie': response.data.montant_sortie as number })
        form.setFieldsValue({ 'montant_benefice': response.data.montant_benefice as number })
        form.setFieldsValue({ 'montant_convert': response.data.montant_convert as number })
        form.setFieldsValue({ 'code_facture': response.data.code_facture })
        form.setFieldsValue({ 'devise_entre': response.data.deviseentre.code })
        form.setFieldsValue({ 'devise_sortie': response.data.devisesortie.code })
        form.setFieldsValue({ 'client_id': response.data.client_id })
        console.log('devise entre', response.data.deviseentre.code);
        form.setFieldsValue({ 'devise_entre': response.data.deviseentre.code })
        setLoading(false)
      })
      .catch(error => {
        console.log('error not found', error);
      });
  }
  const typeCompteDebiter = [
    { code: 'bureau', type: 'Compte Bureau' },
    { code: 'globale', type: 'Compte Globale' }
  ]

  return <>
    <Card
      style={{ marginTop: 16 }}
      type="inner"
      title="SORTIE  DE CAISSE"
      extra={<Button
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        Nouveau client
      </Button>}
    >
      <Form {...layout} name="control-hooks" form={form} onFinish={onFinish} validateMessages={validateMessages}>
        <Row>
          <Col span={18}  >
            <Form.Item label="Numero reçu" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
              <Form.Item
                name='numero_recu'
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <Input
                  placeholder="N° reçu "
                />
              </Form.Item>
              <Form.Item name='date_facture' style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}  >
                <DatePicker format="YYYY-MM-DD" defaultValue={moment()} placeholder="date" style={{ width: '100%' }} />
              </Form.Item>
            </Form.Item>

            <Form.Item name='client_id' label="Client" rules={[{ required: true }]}>

              <SelectSearchInput
                onChange={(v) => onChange('client', v)}
                data={clients}
                label='prenom'
                label2='nom'
                label3='telephone'
                valueLabel='id'
                onSearch={(value) => onSearch(value)}
                placeholder="sectionnez le client"
              />
            </Form.Item>
            <Form.Item label="Info Réceveur" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
              <Form.Item
                name='nom_deposant'
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <Input placeholder="nom receveur" />

              </Form.Item>
              <Form.Item
                name='tel_deposant'
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
              >
                <Input placeholder="téléphone réceveur" />
              </Form.Item>
            </Form.Item>

            <Form.Item name='motif' label="Modif" rules={[{ required: true }]}>
              <Input.TextArea placeholder="motif" />
            </Form.Item>
            <Form.Item label="* Devises" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
              <Form.Item
                name='devise_entre'
                initialValue='GNF'
                rules={[{ required: true }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <SelectSearchInput
                  onChange={(v) => onChange('devise_entre', v)}
                  onSearch={(value) => onSearch(value)}
                  data={devises}
                  label='code'
                  defaultVal={id ? form.getFieldValue('devise_entre') : ''}
                  valueLabel='code'
                  placeholder="Selectionnez la devise d'entré"
                />
              </Form.Item>
              <Form.Item
                name='devise_sortie'
                rules={[{ required: true }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
              >
                <SelectSearchInput
                  onChange={(v) => onChange('devise_sortie', v)}
                  data={devises}
                  label='code'
                  valueLabel='code'
                  onSearch={(value) => onSearch(value)}
                  placeholder="sectionnez la devise de sortie"
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="Taux *" style={{ marginBottom: 0 }} >
              <Form.Item
                initialValue={1}
                name='taux'
                rules={[{ type: 'number', required: true, min: 0, }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <InputNumber
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name='code_facture'
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
              >
                <Input
                  placeholder="Code facture"
                />
              </Form.Item>

            </Form.Item>
            <Form.Item label="* Montants" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
              <Form.Item
                name='montant_entre'
                rules={[{ type: 'number', required: true, min: 0 }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <InputNumber
                  onChange={(value: any) => onMontantChange(value)}
                  placeholder="Montant d'entré" style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item

                name='montant_benefice'
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                initialValue={0}
              >
                <InputNumber
                  disabled
                  onChange={(value: any) => onMontantFraisChange(value)}
                  placeholder="Montant frais"
                  style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item label="Total " style={{ marginBottom: 0 }} rules={[{ required: true }]}>
              <Form.Item
                name='montant_sortie'
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <InputNumber
                  disabled
                  onChange={(value) => onMontantSortieChange(value)}
                  placeholder="Montant à payer" style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item
                name='compte_debite'
                // rules={[{ required: true }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
              >
                <SelectSearchInput
                  onChange={(v) => onChange('compte_debite', v)}
                  data={typeCompteDebiter}
                  label='type'
                  valueLabel='code'
                  onSearch={(value) => onSearch(value)}
                  placeholder="sectionnez le compte à debiter"
                  defaultVal='globale'
                  disabled
                />
              </Form.Item>
            </Form.Item>
            <Form.Item initialValue='sortie' name='type_facture' noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Space>
                {
                  !id ? (
                    <Button loading={loading} type="primary" htmlType="submit">
                      Enregistrer
                    </Button>
                  ) : (
                    <Button loading={editloading} type="primary" htmlType="submit">
                      Modifier
                    </Button>
                  )
                }
                <Button htmlType="button" onClick={onReset}>
                  Annuler
                </Button>
              </Space>
            </Form.Item>


          </Col>
          <Divider type="vertical" />

          <Form.Item
            name='montant_convert'
            label={form.getFieldValue('devise_sortie')}
          >
            <InputNumber
              disabled
              placeholder="Montant converti"
              style={{ width: '100%' }}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Row>
      </Form>
      <FormModal
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </Card>
    {
      redirect ? (<Redirect to="/factures/sorties" />) : ''
    }
  </>;
}
export default Add;

