import React from 'react';
import { Link } from 'react-router-dom'
import { Table, Tag, Space, Card } from 'antd';
import axios from '../services/axios'
import moment from 'moment'

interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}


const Taux_jours = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 100
  });

  React.useEffect(() => {
    fetchData({ pagination } as IProps);
  }, [])

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  const columns = [
    {
      title: '1 USD=>GNF',
      dataIndex: 'taux_USD',
      key: 'taux_USD',
    },
    {
      title: '1 EURO=>GNF',
      dataIndex: 'taux_EURO',
      key: 'taux_EURO',
    },
    {
      title: '1 FCFA=>GNF',
      dataIndex: 'taux_FCFA',
      key: 'taux_FCFA',
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: string) => <a>{moment(text).format()}</a>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Link to={`/add-taux-jour/edit/${record.id}`}>Editer</Link>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const fetchData = (params: any) => {
    setLoading(true);
    axios.get('/taux-jours')
      .then(response => {
        const dataPagination = getRandomuserParams(params)
        console.log(response);
        setData(response.data);
        setLoading(false)
        setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };


  return <>
    <Card size="small" title={<h4>La liste des Taux du jour</h4>} extra={<Link to="/add-taux-jours" className="btn btn-primary">Nouveau</Link>} >
      <Table
        columns={columns}
        rowKey={(record: any) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </Card>
  </>;
}

export default Taux_jours;