import React, { useState } from 'react';
import { Button, Modal, Form, Input, InputNumber, DatePicker } from 'antd';
import SelectSearchInput from '../../utils/components/selectSearchInput';
import moment from 'moment'
interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
  clients: any[];
  devises: any[];
}

const CollectionCreateForms: React.FC<CollectionCreateFormProps> = ({
  visible,
  onCreate,
  onCancel,
  clients,
  devises
}) => {
  const [form] = Form.useForm();
  React.useEffect(() => {
    console.log('monted');
  }, [])
  const onReset = () => {
    form.resetFields();
  }
  const config = {
    rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
  };

  const onChange = (name: string, value: any) => {
    form.setFieldsValue({ [name]: value })
  }
  const onSearch = (val: any) => {
    console.log(val);
  }

  const typeCompteDebiter = [
    { id: 'bureau', prenom: '', nom: 'Compte Bureau', telephone: '' },
    { id: 'globale', prenom: '', nom: 'Compte Globale', telephone: '' }
  ]
  return (
    <Modal
      visible={visible}
      title="Enregistrer une nouvelle injection de fond "
      okText="Enregistrer"
      cancelText="annuler"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"

      >
        <Form.Item label="Numero reçu" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
          <Form.Item
            name='numero_recu'
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input
              placeholder="N° reçu "
            />
          </Form.Item>
          <Form.Item name='date_depense' style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}  >
            <DatePicker format="YYYY-MM-DD" defaultValue={moment()} placeholder="date" style={{ width: '100%' }} />
          </Form.Item>
        </Form.Item>

        <Form.Item name='montant' label="Montant" rules={[{ required: true }]}>
          <InputNumber
            placeholder="Montant"
            style={{ width: '100%' }}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>

        <Form.Item name='devise_id'
          rules={[{ required: true }]}
          label="Devise"
        >
          <SelectSearchInput
            onChange={(v) => onChange('devise_id', v)}
            onSearch={(value) => onSearch(value)}
            label='code'
            valueLabel='id'
            data={devises}
            placeholder="Selectionnez la devise"
          />
        </Form.Item>
        <Form.Item
          name='compte_debite'
          rules={[{ required: true }]}
          label="Compte ou client à ajouter"
        >
          <SelectSearchInput
            onChange={(v) => onChange('compte_debite', v)}
            data={[...clients, ...typeCompteDebiter]}
            label='prenom'
            label2='nom'
            label3='telephone'
            valueLabel='id'
            onSearch={(value) => onSearch(value)}
            placeholder="sectionnez le compte à ajouter"
          />
        </Form.Item>

        <Form.Item name='motif' label="Modif" rules={[{ required: true }]} >
          <Input.TextArea />
        </Form.Item>
        <Form.Item initialValue='inject' name='type_operation' noStyle>
          <Input type="hidden" />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default CollectionCreateForms;